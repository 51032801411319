import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

const theme = {
  primary: colors.green
};

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: "sm"
  },
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  },
  icons: {
    defaultSet: "mdi"
  },

  fontFamily: "NotoSanLao, sans-serif"
});
