import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "layouts-default-index" */
        "@/layouts/default/Index"
      ),
    children: [
      {
        path: "/",
        name: "ສະແກນ QR Code",
        component: () =>
          import(
            /* webpackChunkName: "views-dashboard" */
            "@/views/ScanQRCode"
          )
      },
      {
        path: "/add-customer",
        name: "ເພີ່ມລູກຄ້າ",
        component: () => import("@/views/AddCustomer")
      },
      {
        path: "/profile",
        name: "ໂປຣຟາຍ",
        component: () => import("@/views/Profile")
      }
    ]
  },
  {
    path: "/authentication",
    component: () =>
      import(
        /* webpackChunkName: "layouts-authentication-index" */
        "@/layouts/authentication/Index"
      ),
    children: [
      {
        path: "sign-in",
        name: "ເຂົ້າສູ່ລະບົບ",
        component: () =>
          import(
            /* webpackChunkName: "views-sign-in" */
            "@/views/authentication/SignIn"
          )
      }
    ]
  }
];
const router = new VueRouter({
  mode: process.env.NODE_ENV === "production" ? "history" : "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
