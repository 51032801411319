import Vue from "vue";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import {
  email,
  max,
  min,
  numeric,
  required,
  digits,
  confirmed
} from "vee-validate/dist/rules";

extend("digits", {
  ...digits,
  message: "{_field_} must be {length} digits. ({_value_})"
});
extend("email", {
  ...email,
  message: "Email must be valid"
});
extend("max", {
  ...max,
  message: "{_field_} ບໍ່ຄວນເກີນ {length} ຕົວອັກສອນ"
});
extend("min", {
  ...min,
  message: "{_field_} ຕ້ອງມີຢ່າງໜ້ອຍ {length} ຕົວອັກສອນ"
});
extend("numeric", {
  ...numeric,
  message: "{_field_} ປ້ອນສະເພາະຕົວເລກເທົ່ານ໊ນ"
});
extend("required", {
  ...required,
  message: "{_field_} ບໍ່ສາມາດວ່າງໄດ້"
});
extend("confirmed", {
  ...confirmed,
  message: "Password and password confirmation do not match."
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
