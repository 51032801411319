const state = {
  drawer: true,
  gradient: "rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)",
  items: [
    { title: "ສະແກນ QR Code", icon: "mdi-qrcode-scan", to: "/" },
    // {
    //   title: "Registration",
    //   icon: "mdi-account-multiple-plus-outline",
    //   to: "registration"
    // },
    {
      title: "ເພີ່ມລູກຄ້າ",
      icon: "mdi-account-multiple-plus-outline",
      to: "/add-customer"
    },
    { title: "ໂປຣຟາຍ", icon: "mdi-account-circle", to: "/profile" }
  ]
};

const getters = {
  getDrawer: state => state.drawer
};

const mutations = {
  setDrawer(state, data) {
    state.drawer = data;
  }
};

const actions = {
  toggleDrawer({ commit }, value) {
    commit("setDrawer", value);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
